
























































































































































import { Component, Vue } from "vue-property-decorator";
import {BASE_API_URL, EventBus, setInstanceVariables} from "@/config";
import Axios from "axios";
import Picture from "@/views/Picture.vue"
import {identifyUserSession} from "@/logrocket";

@Component({
  components: {
    Picture,
  },
})
export default class AppSideNavComponent extends Vue {
  /**
   * List of links which are going to be displayed on ui.
   */
  public get navLinks() {
    return this.$store.state.navigationLinks;
  }
  public alphaProfile = null;
  public totalResponse = null;
  public availableAccounts: Array<any> = [];
  public selectedUserAccount: string;
  /**
   * Initial state of sidenav
   */
  public navCollapsed = false;
  // public imageCordinates = {
  //   height: 0,
  //   width: 0
  // };
  /**
   * Getter method for session object
   */
  public get session() {
    return this.$store.state.sessionObject;
  }

  /**
   * Getter method of user type
   */
  public get userType() {
    return this.$store.state.sessionObject.type;
  }

  // public get userProfilePicture() {
  //   if (this.userType == "Broker" || this.userType == "Wemlo Processor") {
  //     if (
  //       this.$store.state.sessionObject.userInfo.hasOwnProperty("profilePicture") &&
  //       this.$store.state.sessionObject.userInfo.profilePicture == null
  //     ) {
  //       this.alphaProfile = this.userType == "Wemlo Processor"
  //           ? this.$store.state.sessionObject.userInfo.firstName.slice(0, 1).toUpperCase()
  //           : this.$store.state.sessionObject.userInfo.companyName.slice(0, 1).toUpperCase();
  //       return null;
  //     }
  //     let arr = this.$store.state.sessionObject.userInfo.profilePicture.split(".");
  //     let ext;
  //     let src;
  //     if (this.userType == "Broker") {
  //       ext = arr.pop();
  //       src = arr[0].substring(0, 55);
  //     } else if (this.userType == "Wemlo Processor") {
  //       ext = arr.pop();
  //       src = arr[0].substring(0, 58);
  //     }
  //     // this.setCordinatesOfImage( src + "thumb." + ext);
  //     // return  src + "thumb." + ext;
  //     this.fetchImageAndShow(src+'thumb.'+ext, ext);
  //     return true;
  //   } else if (this.userType == "MLO" || this.userType == "Admin Staff") {
  //     this.alphaProfile = this.$store.state.sessionObject.userInfo.firstName.slice(0, 1);
  //     return null;
  //   }
  // }

  // private async fetchImageAndShow (path, extension) {
  //   try {
  //     let imageElement = document.createElement('img');
  //     let response = await Axios.post(BASE_API_URL+'common/fetchDocument', { path: path });
  //     imageElement.src = 'data:image/'+extension+';base64,'+response.data;
  //     imageElement.id = 'imageProfilePicture';
  //     let dom = document.getElementById('imageElementSideNav');
  //     let domImage = document.getElementById('imageProfilePictureSideNav');
  //     if(domImage) domImage.remove();
  //     if (dom) dom.appendChild(imageElement);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  public get image_thumb(){
    return this.$store.state.profilePictures.thumb;
  }


  public get imageCordinates() {
    // let image = new Image();
    // image.src = this.$store.state.profilePictures.thumb;
    // let returnData ={
    //   height: 0,
    //   width: 0
    // }
    // image.onload = () => {
    //   if (image.height >= image.width) {
    //     returnData.width = image.width / 3;
    //     returnData.height = image.height / 3;
    //   } else {
    //     returnData.width = image.width / 2.2;
    //     returnData.height = image.height / 2;
    //   }
    // };
    return this.$store.state.profilePictures.cordinates;// { width: returnData.width, height: returnData.height }
  }

  /**
   * Getter method of user name;
   */
  public get userName() {
    let {firstName = "", lastName = ""} = this.session.userInfo || {};
    return `${firstName} ${lastName}`;
  }

  public get scenarioDeskCount() {
    return this.$store.state.scenarioDeskCount;
  }

  public set scenarioDeskCount(data) {
    this.$store.state.scenarioDeskCount = data;
  }
  /**
   * for the counterof scenario_desk
   **/
  public async getBorrowerListforScenarioDeskMessageList() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/getBorrowerListforScenarioDeskMessageList", //to get the all the data of scenariodesk
        {
          userType: this.$userType,
          userId: this.$userId
        });
      this.$store.state.scenarioDeskCount = 0;
      response.data.borrowers.forEach(e => {
        e.selectedLenders.forEach(element => {
          element.conversation.forEach((c, i) => {
            if (c.type == "response" && !c.isSeen && !c.isCleared) {
              this.$store.state.scenarioDeskCount++;
            }
          });
        });
      });
    } catch (error) {
      console.log(error);
    }
  }



  /**
   * Logout functionality
   */
  // Logout from sudo mode
  async logOutIdentityAssumedMode() {
    try {
      await this.$store.dispatch("setIdentityAssumedMode", false);
      await this.$store.dispatch("setUserInfoForIdentityAssumedMode", {});
      await this.$store.dispatch("setSession", this.$store.state.copySuperAdminSessionObject);
      await setInstanceVariables();
      await this.$store.dispatch("setSidenavLinks", this.$store.state.copySuperAdminSessionObject);
      await this.$router.push({path: this.$getCurrentUserTypePath("manage-all-user")});
    } catch (error) {
      console.log(error)
    }
  }

  logOut() {
    if (this.$store.state.isIdentityAssumedMode) {
      this.logOutIdentityAssumedMode()
    } else {
    EventBus.$emit('closeWeLearn',false);//Closing Learn more video modal of every task on logout .
    this.userDeviceDetails();
    localStorage.setItem("logout-event", "user_logged_out" + Math.random());

    /**
     * Remove any login credentials from storage
     */
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    localStorage.removeItem("rememberMeToken");

    this.$store.dispatch("clearSession");
    this.$router.push({ name: "Login" });
    }
  }


  public async userDeviceDetails(){
    await Axios.post(
        BASE_API_URL + "authentication/userDeviceDetails",
        {userId: this.$userId, userType: this.$userType, type: 'Logout' });
  }

  public async getUserAccounts(){
    this.availableAccounts = this.$store.state.sessionObject.availableAccounts || [];
  }

  public async switchUserAccount(){
    const selectedAcc = this.availableAccounts.find(acc => acc.userId === this.selectedUserAccount);
    /**
     * Set all the user's information to vuex data store
     */
    await this.$store.dispatch("updateSessionData", selectedAcc.userInfo);
    await this.$store.dispatch("updateSessionUserType", selectedAcc.type);
    await this.$store.commit("SET_SESSION_TOKEN", selectedAcc.token);
    await this.$store.commit("UPDATE_SESSION_USER_ID", selectedAcc.userId);
    await setInstanceVariables();
    identifyUserSession(selectedAcc);
    /**
     * Dispatch the information of the user which holds the access rights on to components
     * after onboarding/login.
     */
    await this.$store.dispatch("setSidenavLinks", selectedAcc);
    this.$router.push("/");
  }

  public getBrandingInformation(){
    return this.$store.state.brandingInformation || {};
  }

  async mounted() {
    /**
     * Listen to toggle event of sidenav
     */
    document.getElementById("sidebarCollapse").addEventListener("click", () => {
      let sidebar = document.getElementById("sidebar");
      sidebar.classList.toggle("active");
      this.navCollapsed = !this.navCollapsed;
    });
    this.selectedUserAccount = this.$userId;
    if (
      this.$userType == "MLO" ||
      this.$userType == "Broker" ||
      this.$userType == "Admin Staff"
    )
      this.getBorrowerListforScenarioDeskMessageList();
    if(this.userType=='Broker'||this.userType=='MLO'||this.userType=='Admin Staff' ||this.userType=='Wemlo Processor')
      this.$store.dispatch('statusOfProfilePicture', { status: this.$store.state.profilePictures.status, image: this.$store.state.sessionObject.userInfo.profilePicture, name: this.$store.state.sessionObject.userInfo.firstName });
    await this.getUserAccounts();
  }

}
